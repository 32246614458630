import { UserIcon } from "@heroicons/react/24/solid"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import { FC } from "react"

interface PlayerPicPros {
  playerPic: any
}

const PlayerPicComponent: FC<PlayerPicPros> = ({
  playerPic = undefined,
}: PlayerPicPros) => {
  if (playerPic?.file === undefined || playerPic?.file === null) {
    return <UserIcon className="h-16 w-16 text-primary lg:h-20 lg:w-20" />
  }

  const image = getImage(playerPic.file)
  if (image === undefined) {
    return <UserIcon className="h-16 w-16 text-primary lg:h-20 lg:w-20" />
  }

  return (
    <GatsbyImage
      image={image}
      className="h-16 w-16 lg:h-20 lg:w-20"
      imgClassName="rounded-full"
      alt=""
    />
  )
}

export default PlayerPicComponent
