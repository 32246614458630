import Headline from "components/general/headline"
import Layout from "components/layout"
import TeamsComponent from "components/team/teams"
import * as React from "react"

const TeamsPage = () => {
  const seo = {
    metaTitle: "Mannschaften",
    metaDescription: "Faustball-Mannschaften des MTV Vorsfelde",
  }

  return (
    <Layout seo={seo} showSidebar={false}>
      <div className="main-box">
        <Headline
          headline="Mannschaften"
          subHeadline="Aktive Faustball Mannschaften des MTV Vorsfelde"
        />
        <TeamsComponent />
      </div>
    </Layout>
  )
}

export default TeamsPage
