import * as React from "react"
import PlayerPicComponent from "./playerPic"

const PlayerComponent = ({ player }) => {
  const getPositions = () => {
    if (
      player.positions === undefined ||
      player.positions == null ||
      player.positions.length == 0
    ) {
      return <></>
    }

    return player.positions.map(p => p.name).join(", ")
  }

  return (
    <div className="flex items-center space-x-4 lg:space-x-6">
      <PlayerPicComponent playerPic={player.picture} />
      <div className="space-y-1 text-lg font-medium leading-6">
        <h3 className="dark:text-white">
          {player.firstName} {player.lastName}
        </h3>
        <p className="text-primary dark:text-primary-light">{getPositions()}</p>
      </div>
    </div>
  )
}

export default PlayerComponent
