import { graphql, StaticQuery } from "gatsby"
import * as React from "react"
import PlayerComponent from "./player"

const query = graphql`
  {
    allStrapiTeam(sort: { order: ASC, fields: sort }) {
      edges {
        node {
          id
          name
          description
          players {
            id
            firstName
            lastName
            number
            positions {
              id
              name
            }
            picture {
              file {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`

const TeamsComponent = () => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        return (
          <div className="mx-auto max-w-7xl divide-y-2 divide-gray-700 px-4 sm:px-6 lg:px-8">
            {data.allStrapiTeam.edges
              .map(t => t.node)
              .map(team => {
                return (
                  <div
                    className="grid grid-cols-1 gap-12 pt-6 pb-6 lg:grid-cols-3 lg:gap-8"
                    key={team.id}
                  >
                    <div className="space-y-5 sm:space-y-4">
                      <h2 className="text-3xl font-extrabold tracking-tight dark:text-white sm:text-4xl">
                        {team.name}
                      </h2>
                      <p className="text-xl text-gray-500 dark:text-gray-300">
                        {team.description}
                      </p>
                    </div>
                    <div className="lg:col-span-2">
                      <ul
                        role="list"
                        className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8"
                      >
                        {team.players
                          // Since it is currently not possible to sort a nested relationship
                          // I'm sorting it by hand.
                          // Parameter to sort by is a player's shirt number
                          .sort((n1, n2) => {
                            if (n1.number > n2.number) return 1
                            if (n1.number < n2.number) return -1
                            return 0
                          })
                          .map(player => (
                            <li key={player.id}>
                              <PlayerComponent player={player} />
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                )
              })}
          </div>
        )
      }}
    />
  )
}

export default TeamsComponent
